exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brad-js": () => import("./../../../src/pages/brad.js" /* webpackChunkName: "component---src-pages-brad-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-521-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/521.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-521-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-a-song-for-you-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/a-song-for-you.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-a-song-for-you-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-computer-chip-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/computer-chip.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-computer-chip-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-cubezoid-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/cubezoid.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-cubezoid-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-first-post-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/first-post.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-first-post-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-new-layout-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/new-layout.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-new-layout-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-new-office-render-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/new-office-render.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-new-office-render-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-zimaboard-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/zimaboard.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-blog-zimaboard-mdx" */),
  "component---src-pages-office-js": () => import("./../../../src/pages/office.js" /* webpackChunkName: "component---src-pages-office-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

